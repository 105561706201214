// eslint-disable-next-line no-restricted-imports
import {
  MarketLink as MarketLinkBinding,
  MarketTableRow as MarketTableRowBinding,
  MarketInputText as MarketInputTextBinding,
} from '@market/react';
import { MarketBindingWithoutClassName } from '@squareup/dex-types-shared-ui';
import { TestProps, TrackedProps } from '@squareup/dex-types-shared-utils';
import React from 'react';

import { CreateMarketComponent } from './MarketComponent';

type GetComponentProps<T> = T extends
  | React.ComponentType<infer P>
  | React.Component<infer P>
  ? P
  : never;

type WrapperComponentProps<T> = GetComponentProps<T> &
  MarketBindingWithoutClassName<
    React.ComponentProps<typeof MarketLinkBinding>
  > &
  TrackedProps &
  TestProps;

const MarketLink = CreateMarketComponent<
  WrapperComponentProps<typeof MarketLinkBinding>,
  globalThis.HTMLMarketLinkElement
>(MarketLinkBinding);

const MarketTableRow = CreateMarketComponent<
  GetComponentProps<typeof MarketTableRowBinding> & TrackedProps & TestProps,
  globalThis.HTMLMarketTableRowElement
>(MarketTableRowBinding);

const MarketInputText = CreateMarketComponent<
  GetComponentProps<typeof MarketInputTextBinding> & TrackedProps & TestProps,
  globalThis.HTMLMarketInputTextElement
>(MarketInputTextBinding);

export { MarketLink, MarketTableRow, MarketInputText };
