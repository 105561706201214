import { TestProps, TrackedProps } from '@squareup/dex-types-shared-utils';
import React, { forwardRef } from 'react';

function CreateMarketComponent<P extends TrackedProps & TestProps, E>(
  MarketComponent: React.FunctionComponent<P>
) {
  // eslint-disable-next-line react/display-name
  const WrapperComponent = forwardRef<E, P>(
    ({ trackingId, trackingExtra, testId, ...rest }, ref) => {
      const componentJSX = (
        // @ts-ignore Rest parameters are valid here.
        <MarketComponent
          {...rest}
          data-testid={testId}
          data-tracking-id={trackingId}
          data-tracking-extra={trackingExtra}
          ref={ref}
        ></MarketComponent>
      );

      return componentJSX;
    }
  );

  return WrapperComponent;
}

export { CreateMarketComponent };
