import { isServerContext } from '@squareup/dex-utils-environment';
import {
  createSavtCookie,
  savtCookieName,
} from '@squareup/dex-utils-shared-cookie';
import { serialize } from 'cookie';
import { GetServerSidePropsContext, NextPageContext } from 'next';

const withSqAvtPagesDataFetchMiddleware = (
  ctx: GetServerSidePropsContext | NextPageContext
) => {
  if (!isServerContext()) {
    return;
  }

  if (
    !(ctx as unknown as GetServerSidePropsContext)?.req?.cookies?.[
      savtCookieName
    ]
  ) {
    const savt = createSavtCookie();

    // This will ensure the current request uses the avt throughout the codepath
    // eslint-disable-next-line no-param-reassign
    (ctx as unknown as GetServerSidePropsContext).req.cookies[savtCookieName] =
      savt.value;

    ctx.res?.setHeader(
      'set-cookie',
      serialize(savt.name, savt.value, {
        maxAge: savt.maxAge,
        domain: savt.domain,
        path: savt.path,
        secure: savt.secure,
      })
    );
  }
};

export { withSqAvtPagesDataFetchMiddleware };
