import * as React from 'react';
import { SVGProps } from 'react';

const SvgPlus = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13 6h-2v5H6v2h5v5h2v-5h5v-2h-5V6Z" fill="#7B61FF" />
  </svg>
);
export default SvgPlus;
