import {
  getReleaseTrain,
  useDocsDispatch,
} from '@squareup/dex-store-access-dex-tech-docs-actions';
import { useReleaseTrain } from '@squareup/dex-store-access-dex-tech-docs-selectors';
import { DocsQueryParams } from '@squareup/dex-types-shared-docs';
import { Box, Heading10 } from '@squareup/dex-ui-shared-base';
import {
  MarketDialog,
  MarketButton,
  MarketHeader,
  MarketInputText,
  MarketRow,
} from '@squareup/dex-ui-shared-market';
import { useRouter } from 'next/router';
import React, {
  FC,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import styles from '../admin-toolbar.module.css';

const ReleaseTrainFeature: FC = () => {
  const { push, query } = useRouter();
  const dispatch = useDocsDispatch();

  const inputRef = useRef<globalThis.HTMLMarketInputTextElement>(null);

  const releaseTrain = useReleaseTrain();
  const [isReleaseTrainDialogOpen, setIsReleaseTrainDialogOpen] =
    useState(false);

  const [goClicked, setGoClicked] = useState(false);
  const [clearClicked, setClearClicked] = useState(false);

  useEffect(() => {
    if (releaseTrain.inView) {
      return;
    }

    const releaseTrainParamValue = query[DocsQueryParams.train];
    // Kick of fetching the release train if we don't already have it
    if (releaseTrainParamValue && !releaseTrain.inView) {
      dispatch(
        getReleaseTrain({
          releaseTrain: releaseTrainParamValue as string,
        })
      );
    }
    // Only change when the route updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const onClear = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);

    const releaseTrainParamValue = searchParams.get(DocsQueryParams.train);
    if (!releaseTrainParamValue) {
      setIsReleaseTrainDialogOpen(false);
    }
    setClearClicked(true);

    searchParams.delete(DocsQueryParams.train);

    const url = `${window.location.pathname}${
      [...searchParams.keys()].length > 0 ? `?${searchParams.toString()}` : ''
    }`;
    await push(url);

    setClearClicked(false);
    setIsReleaseTrainDialogOpen(false);
  }, [push]);

  const onGo = useCallback(async () => {
    if (!inputRef.current) {
      setIsReleaseTrainDialogOpen(false);
      return;
    }

    if (inputRef.current.value.length === 0) {
      setGoClicked(true);
      await onClear();
      return;
    }

    if (
      releaseTrain.inView &&
      inputRef.current.value === releaseTrain?.state.slug
    ) {
      setIsReleaseTrainDialogOpen(false);
    }

    setGoClicked(true);

    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set(DocsQueryParams.train, inputRef.current.value);

    const url = `${window.location.pathname}${
      [...newSearchParams.keys()].length > 0
        ? `?${newSearchParams.toString()}`
        : ''
    }`;
    await push(url);

    setGoClicked(false);
    setIsReleaseTrainDialogOpen(false);
  }, [onClear, push, releaseTrain.inView, releaseTrain?.state.slug]);

  const onInputKeydown = useCallback<
    KeyboardEventHandler<globalThis.HTMLMarketInputTextElement>
  >(
    async (e) => {
      if (e.key === 'Enter') {
        await onGo();
      }
    },
    [onGo]
  );

  return (
    <>
      <MarketRow size="small" trackingId="release-train-row">
        <label slot="label">Release train</label>
        <MarketButton
          className={styles.control}
          slot="control"
          size="small"
          testId="release-train-button"
          trackingId="release-train-button"
          onClick={() => setIsReleaseTrainDialogOpen(true)}
        >
          Open
        </MarketButton>
      </MarketRow>
      {isReleaseTrainDialogOpen && (
        <MarketDialog
          onMarketDialogDismissed={() => setIsReleaseTrainDialogOpen(false)}
        >
          <Box as="main" className={styles.dialog}>
            <MarketHeader>
              <Heading10>Enter a release train</Heading10>
              <MarketButton
                slot="actions"
                size="small"
                rank="primary"
                trackingId="release-train-dialog-go"
                testId="release-train-dialog-go"
                onClick={onGo}
                {...(goClicked && {
                  disabled: true,
                  isLoading: true,
                })}
              >
                Go
              </MarketButton>
              <MarketButton
                slot="actions"
                size="small"
                rank="primary"
                variant="destructive"
                trackingId="release-train-dialog-clear"
                testId="release-train-dialog-clear"
                onClick={onClear}
                {...(clearClicked && {
                  disabled: true,
                  isLoading: true,
                })}
              >
                Clear
              </MarketButton>
            </MarketHeader>
            <Box margin={{ bottom: '1x' }} />
            <MarketInputText
              testId="release-train-input"
              trackingId="release-train-input"
              autofocus={true}
              size="medium"
              value={
                releaseTrain.inView && releaseTrain.state.slug
                  ? releaseTrain.state.slug
                  : ''
              }
              ref={inputRef}
              onKeyDown={onInputKeydown}
            >
              <label>Slug</label>
            </MarketInputText>
          </Box>
        </MarketDialog>
      )}
    </>
  );
};

export { ReleaseTrainFeature };
