import { DocsQueryParams } from '@squareup/dex-types-shared-docs';
import { Box, Heading10 } from '@squareup/dex-ui-shared-base';
import {
  MarketDialog,
  MarketButton,
  MarketHeader,
  MarketInputText,
  MarketRow,
} from '@squareup/dex-ui-shared-market';
import { useRouter } from 'next/router';
import React, {
  FC,
  KeyboardEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';

import styles from '../admin-toolbar.module.css';

const UnpublishedOasFeature: FC = () => {
  const {
    push,
    query: {
      [DocsQueryParams.unpublishedOas]: unpublishedOasParamValue,
      [DocsQueryParams.legacyUnpublishedOas]: legacyOasParamValue,
    },
  } = useRouter();

  const unpublishedId = (unpublishedOasParamValue ||
    legacyOasParamValue) as string;

  const inputRef = useRef<globalThis.HTMLMarketInputTextElement>(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [goClicked, setGoClicked] = useState(false);
  const [clearClicked, setClearClicked] = useState(false);

  const onClear = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);

    const unpublishedParamValue =
      searchParams.get(DocsQueryParams.unpublishedOas) ||
      searchParams.get(DocsQueryParams.legacyUnpublishedOas);

    if (!unpublishedParamValue) {
      setIsDialogOpen(false);
    }
    setClearClicked(true);

    searchParams.delete(DocsQueryParams.unpublishedOas);
    searchParams.delete(DocsQueryParams.legacyUnpublishedOas);

    const url = `${window.location.pathname}${
      [...searchParams.keys()].length > 0 ? `?${searchParams.toString()}` : ''
    }`;
    await push(url);

    setClearClicked(false);
    setIsDialogOpen(false);
  }, [push]);

  const onGo = useCallback(async () => {
    if (!inputRef.current) {
      setIsDialogOpen(false);
      return;
    }

    if (inputRef.current.value.length === 0) {
      setGoClicked(true);
      await onClear();
      return;
    }

    if (unpublishedId === inputRef.current.value) {
      setIsDialogOpen(false);
    }

    setGoClicked(true);

    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set(DocsQueryParams.unpublishedOas, inputRef.current.value);

    const url = `${window.location.pathname}${
      [...newSearchParams.keys()].length > 0
        ? `?${newSearchParams.toString()}`
        : ''
    }`;
    await push(url);

    setGoClicked(false);
    setIsDialogOpen(false);
  }, [onClear, push, unpublishedId]);

  const onInputKeydown = useCallback<
    KeyboardEventHandler<globalThis.HTMLMarketInputTextElement>
  >(
    async (e) => {
      if (e.key === 'Enter') {
        await onGo();
      }
    },
    [onGo]
  );

  return (
    <>
      <MarketRow size="small" trackingId="unpublished-oas-row">
        <label slot="label">OAS preview</label>
        <MarketButton
          className={styles.control}
          slot="control"
          size="small"
          testId="unpublished-oas-button"
          trackingId="unpublished-oas-button"
          onClick={() => setIsDialogOpen(true)}
        >
          Open
        </MarketButton>
      </MarketRow>
      {isDialogOpen && (
        <MarketDialog onMarketDialogDismissed={() => setIsDialogOpen(false)}>
          <Box as="main" className={styles.dialog}>
            <MarketHeader>
              <Heading10>Enter an oas build id</Heading10>
              <MarketButton
                slot="actions"
                size="small"
                rank="primary"
                trackingId="unpublished-oas-dialog-go"
                testId="unpublished-oas-dialog-go"
                onClick={onGo}
                {...(goClicked && {
                  disabled: true,
                  isLoading: true,
                })}
              >
                Go
              </MarketButton>
              <MarketButton
                slot="actions"
                size="small"
                rank="primary"
                variant="destructive"
                trackingId="unpublished-oas-dialog-clear"
                testId="unpublished-oas-dialog-clear"
                onClick={onClear}
                {...(clearClicked && {
                  disabled: true,
                  isLoading: true,
                })}
              >
                Clear
              </MarketButton>
            </MarketHeader>
            <Box margin={{ bottom: '1x' }} />
            <MarketInputText
              testId="unpublished-oas-input"
              trackingId="unpublished-oas-input"
              autofocus={true}
              size="medium"
              value={unpublishedId || ''}
              ref={inputRef}
              onKeyDown={onInputKeydown}
            >
              <label>Build id</label>
            </MarketInputText>
          </Box>
        </MarketDialog>
      )}
    </>
  );
};

export { UnpublishedOasFeature };
