/* eslint-disable complexity */
import { setReleaseTrain } from '@squareup/dex-data-dex-tech-docs-view-state-slices';
import {
  followClientSideRedirects,
  loadDocPageInView,
  loadNav,
  loadDiffPageContent,
  loadReleaseTrain,
} from '@squareup/dex-store-access-dex-tech-docs-actions';
import { SliceError, StateNodeError } from '@squareup/dex-types-data-state';
import { DocsQueryParams } from '@squareup/dex-types-shared-docs';
import {
  isProductionEnvironment,
  isPublicContext,
  isServerContext,
} from '@squareup/dex-utils-environment';
import { log } from '@squareup/dex-utils-shared-logging';
import { withSqAvtPagesDataFetchMiddleware } from '@squareup/dex-utils-shared-next-pages-data-fetch-middleware';
import { runAndRecordFrameworkMetrics } from '@squareup/dex-utils-shared-server-framework-metrics';
import { GetServerSidePropsContext } from 'next';
import { v4 } from 'uuid';

import { configureApp } from './app-config';
import { initializeApp } from './server-app-init';
import {
  setupS2sFetch,
  setupServerLogger,
  setupServerMetrics,
} from './server-module-setup';

async function setupApp(ctx: GetServerSidePropsContext) {
  const requestId = v4();
  let initError: StateNodeError | undefined = undefined;
  let serverData: Awaited<ReturnType<typeof initializeApp>>['data'] = {};

  if (isServerContext()) {
    await setupServerMetrics();
    await setupS2sFetch();
    await setupServerLogger();
  }

  const {
    error: configError,
    isLoading: configIsLoading,
    store,
  } = configureApp({ requestId });

  if (!configError && !configIsLoading) {
    const serverInitProps = {
      headers: ctx.req?.headers,
      cookies: ctx.req?.cookies,
      requestId,
    };

    const initResult = await initializeApp(serverInitProps);

    initError = initResult.error;
    serverData = initResult.data;
  }

  return {
    configError,
    initError,
    store,
    serverData,
  };
}

function notFoundIfProduction(ctx: GetServerSidePropsContext) {
  if (isProductionEnvironment() && isPublicContext()) {
    if (ctx.res) {
      // eslint-disable-next-line no-param-reassign
      ctx.res.statusCode = 404;
      ctx.res.end();
    }

    return {
      responseCode: 404,
      returnVal: {},
    };
  }

  return null;
}

function getInitialPropsDocPage(ctx: GetServerSidePropsContext) {
  withSqAvtPagesDataFetchMiddleware(ctx);

  return runAndRecordFrameworkMetrics(
    { group: 'docs', handler: 'page' },
    async () => {
      const res = ctx.res;
      const { configError, initError, store, serverData } = await setupApp(ctx);

      log.info(
        `http request: ${(ctx as unknown as { asPath: string }).asPath}`
      );

      const previousDocState = store?.getState().docsShellViewState;
      let isPreview = Boolean(ctx.query[DocsQueryParams.preview]);
      const unparsedReleaseTrain = ctx.query[DocsQueryParams.train];
      let releaseTrain =
        typeof unparsedReleaseTrain === 'string'
          ? unparsedReleaseTrain
          : unparsedReleaseTrain?.join('/') || undefined;
      let contentfulEnvironment = ctx.query[DocsQueryParams.contentfulEnv] as
        | string
        | undefined;

      // Don't do preview, release train logic, or contentful environments in production
      if (
        (isPreview || releaseTrain || contentfulEnvironment) &&
        isProductionEnvironment() &&
        isPublicContext()
      ) {
        isPreview = false;
        releaseTrain = undefined;
        contentfulEnvironment = undefined;
      }

      if (isPreview && releaseTrain) {
        const statusCode = 400;
        if (res) {
          res.statusCode = statusCode;
        }

        return {
          responseCode: statusCode,
          returnVal: {
            data: {
              ...serverData,
              doc: {
                error: {
                  error:
                    'cannot set both the "preview" and "train" query params',
                  originalStatus: statusCode,
                  status: 'CUSTOM_ERROR',
                },
              },
            },
          },
        };
      }

      if (releaseTrain && contentfulEnvironment) {
        const statusCode = 400;
        if (res) {
          res.statusCode = statusCode;
        }

        return {
          responseCode: statusCode,
          returnVal: {
            data: {
              ...serverData,
              doc: {
                error: {
                  error:
                    'cannot set both the "train" and "environment" query params',
                  originalStatus: statusCode,
                  status: 'CUSTOM_ERROR',
                },
              },
            },
          },
        };
      }

      let isRedirect = false;
      if (!configError && !initError) {
        const loadActions = [];

        // Load up the release train too
        if (releaseTrain) {
          loadActions.push(store?.dispatch(loadReleaseTrain({ releaseTrain })));
        } else {
          await store?.dispatch(setReleaseTrain({ inView: false }));
        }

        const loadWithRedirect = async () => {
          await store?.dispatch(
            loadDocPageInView({
              query: ctx.query,
              preview: isPreview,
              releaseTrain,
              contentfulEnvironment,
              cookie: ctx.req?.headers?.cookie,
            })
          );
          const page = store?.getState().docsShellViewState?.pageInView?.page;
          if (page?.type === 'redirect') {
            // If server-side, just do a 302 and don't worry about rendering
            if (isServerContext()) {
              res.writeHead(302, { Location: `/docs/${page.redirectTo}` });
              // Calling res.end ensures nextjs won't try to render the page
              res.end();
              return {
                responseCode: 302,
                returnVal: {},
              };
            } else {
              isRedirect = true;
              // If client-side, just follow the redirects as much as you can
              await store?.dispatch(
                followClientSideRedirects({
                  slug: page.redirectTo,
                  preview: isPreview,
                  releaseTrain,
                  contentfulEnvironment,
                })
              );
            }
          }

          return null;
        };

        // Run page load and nav load in parallel
        loadActions.push(loadWithRedirect());

        // Don't fetch the nav if we already have it, OR refetch if the previous nav doesn't
        // match the preview state
        if (
          !previousDocState?.navSet.value ||
          previousDocState.adminFeature.isPreview !== isPreview ||
          previousDocState.adminFeature.releaseTrain.inView !==
            Boolean(releaseTrain)
        ) {
          loadActions.push(
            store?.dispatch(
              loadNav({
                preview: isPreview,
                releaseTrain,
                contentfulEnvironment,
                cookie: ctx.req?.headers?.cookie,
              })
            )
          );
        }

        const loadResults = await Promise.all(loadActions);
        for (const result of loadResults) {
          // Handle in case the redirect occurred
          if (result?.responseCode) {
            return result;
          }
        }
      }

      const docsState = store?.getState().docsShellViewState;

      const notFound = !docsState?.pageInView.page;

      const docsError = docsState?.pageInView.error;

      // error if the release train can't be found
      const releaseTrainError =
        docsState?.adminFeature.releaseTrain.state.error;
      if (releaseTrain && releaseTrainError) {
        const statusCode = releaseTrainError.originalStatus || 404;
        if (res) {
          res.statusCode = statusCode;
        }

        return {
          responseCode: statusCode,
          returnVal: {
            data: {
              ...serverData,
              doc: {
                error: docsState?.adminFeature.releaseTrain.state.error,
              },
            },
          },
        };
      }

      if (notFound && res) {
        res.statusCode = 404;
      }

      // Only docs errors result in a status code error;
      if (docsError && res) {
        res.statusCode = docsError.originalStatus || 500;
      }

      const returnVal = {
        data: {
          ...serverData,
          doc: {
            pageInView: docsState?.pageInView,
            navSet: docsState?.navSet,
            error: docsError,
            notFound,
            preview: docsState?.adminFeature.isPreview,
            releaseTrain,
            contentfulEnvironment,
            didRedirect: isRedirect,
          },
        },
      };

      return {
        responseCode: notFound ? 404 : res?.statusCode || 200,
        returnVal,
      };
    }
  );
}

/**
 * The user should never go to these pages, because they are owned by connectv2-docs
 * If they end up here via client-side navigation, we will reload the page
 * If they end up here via server-side navigation, we return a 404 since it
 * should be impossible
 */
function getInitialPropsDocsApiPage(ctx: GetServerSidePropsContext) {
  withSqAvtPagesDataFetchMiddleware(ctx);

  return runAndRecordFrameworkMetrics(
    { group: 'docs', handler: '/docs/api' },
    async () => {
      if (!isServerContext()) {
        // Force reload to reroute to connectv2-docs
        window.location.assign(
          (ctx as unknown as { asPath: string })?.asPath || '/docs'
        );
        // You can't actually await a window.location.assign, so we'll wait ~5s before giving up and 404'ing
        await new Promise((resolve) => {
          setTimeout(resolve, 5000);
        });
      }

      if (ctx.res) {
        // eslint-disable-next-line no-param-reassign
        ctx.res.statusCode = 404;
        ctx.res.end();
      }

      return {
        responseCode: 404,
        returnVal: {},
      };
    }
  );
}

function getInitialPropsDiffPage(ctx: GetServerSidePropsContext) {
  withSqAvtPagesDataFetchMiddleware(ctx);

  return runAndRecordFrameworkMetrics<unknown>(
    { group: 'docs', handler: 'diff' },
    async () => {
      const blockedResponse = notFoundIfProduction(ctx);
      if (blockedResponse) {
        return blockedResponse;
      }

      const res = ctx.res;
      const { configError, initError, store, serverData } = await setupApp(ctx);

      const isPreview = Boolean(ctx.query[DocsQueryParams.preview]);
      const releaseTrain = ctx.query[DocsQueryParams.train];
      if (isPreview || releaseTrain) {
        const statusCode = 400;
        if (res) {
          res.statusCode = statusCode;
        }

        return {
          responseCode: statusCode,
          returnVal: {
            data: {
              ...serverData,
              doc: {
                error: {
                  error:
                    'cannot set the "preview" or "train" query params on diff pages',
                  originalStatus: statusCode,
                  status: 'CUSTOM_ERROR',
                } as SliceError,
              },
            },
          },
        };
      }

      const previousDocState = store?.getState().docsShellViewState;

      let currentState;
      if (!configError && !initError) {
        await store?.dispatch(
          loadDiffPageContent({ query: ctx.query, preview: false })
        );

        currentState = store?.getState().docsShellViewState.pageInView;
        if (currentState && !currentState.error) {
          await store?.dispatch(
            loadDiffPageContent({ query: ctx.query, preview: true })
          );
        }

        // The nav will always be considered un-diffable, so just fetch the current one
        if (!previousDocState?.navSet.value) {
          await store?.dispatch(loadNav({ preview: false }));
        }
      }

      const previewDocsState = store?.getState().docsShellViewState;
      const notFound = !previewDocsState?.pageInView.page;
      const docsError = previewDocsState?.pageInView.error;

      if (notFound && res) {
        res.statusCode = 404;
      }

      // Only docs errors result in a status code error;
      if (docsError && res) {
        res.statusCode = docsError.originalStatus || 500;
      }

      const returnVal = {
        data: {
          ...serverData,
          doc: {
            pageInView: currentState,
            previewPageInView: previewDocsState?.pageInView,
            navSet: previewDocsState?.navSet,
            error: docsError,
            notFound,
          },
        },
      };

      return {
        responseCode: notFound ? 404 : res?.statusCode || 200,
        returnVal,
      };
    }
  );
}

export {
  getInitialPropsDocPage,
  getInitialPropsDiffPage,
  getInitialPropsDocsApiPage,
};
