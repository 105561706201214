import * as React from 'react';
import { SVGProps } from 'react';

const SvgClose = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8 6.586 4 4 4-4L17.414 8l-4 4 4 4L16 17.414l-4-4-4 4L6.586 16l4-4-4-4L8 6.586Z"
      fill="#7B61FF"
    />
  </svg>
);

export default SvgClose;
