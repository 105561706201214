import {
  setHtmlHighlight,
  setIsPreview,
  setShowMarkdown,
} from '@squareup/dex-data-dex-tech-docs-view-state-slices';
import { Close, Plus } from '@squareup/dex-icons/dex/action';
import { useDocsDispatch } from '@squareup/dex-store-access-dex-tech-docs-actions';
import {
  useHtmlHighlight,
  useShowMarkdown,
} from '@squareup/dex-store-access-dex-tech-docs-selectors';
import { DocsQueryParams } from '@squareup/dex-types-shared-docs';
import { Link, NativeButton } from '@squareup/dex-ui';
import { Box, Paragraph10 } from '@squareup/dex-ui-shared-base';
import {
  MarketButton,
  MarketLink,
  MarketRow,
  MarketToggle,
} from '@squareup/dex-ui-shared-market';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { FC, useCallback, useState } from 'react';

import styles from './admin-toolbar.module.css';
import { ContentfulEnvironmentFeature } from './features/ContentfulEnvironmentFeature';
import { ReleaseTrainFeature } from './features/ReleaseTrainFeature';
import { UnpublishedOasFeature } from './features/UnpublishedOasFeature';

interface AdminToolbarProps {
  features: {
    showMarkdownEnabled?: boolean;
    showPreviewEnabled?: boolean;
    showHtmlHighlightEnabled?: boolean;
    viewDiffEnabled?: boolean;
    exitDiffEnabled?: boolean;
    viewContentfulEntryEnabled?: boolean;
    releaseTrainEnabled?: boolean;
    contentfulEnvironmentEnabled?: boolean;
    unpublishedOasEnabled?: boolean;
  };
  isPreview?: boolean | undefined;
  contentfulEntryId?: string | undefined;
}

const docsPathPart = '/docs';

const AdminToolbar: FC<AdminToolbarProps> = ({
  features = {},
  contentfulEntryId,
  isPreview,
}) => {
  const { push, asPath } = useRouter();
  const dispatch = useDocsDispatch();
  const showingMarkdown = useShowMarkdown();
  const higlightHtml = useHtmlHighlight();

  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isDiffButtonClicked, setIsDiffButtonClicked] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const onShowMarkdownClicked = useCallback(() => {
    dispatch(setShowMarkdown({ showMarkdown: !showingMarkdown }));
  }, [dispatch, showingMarkdown]);

  const onHtmlHighlightClicked = useCallback(() => {
    dispatch(setHtmlHighlight({ highlight: !higlightHtml }));
  }, [dispatch, higlightHtml]);

  const onIsPreviewToggleChanged = useCallback(async () => {
    setIsPreviewLoading(true);
    // Previewing is handled by just routing back to the same url, but
    // with or without the `?preview=true` query parameter
    const searchParams = new URLSearchParams(window.location.search);
    if (isPreview) {
      searchParams.delete(DocsQueryParams.preview);
      await dispatch(setIsPreview({ isPreview: false }));
    } else {
      searchParams.append(DocsQueryParams.preview, 'true');
    }

    const url = `${window.location.pathname}${
      [...searchParams.keys()].length > 0 ? `?${searchParams.toString()}` : ''
    }`;
    await push(url);

    setIsPreviewLoading(false);
  }, [isPreview, dispatch, push]);

  const onDiffButtonClicked = useCallback(() => {
    setIsDiffButtonClicked(true);
  }, []);

  const contentfulEditHref = `https://app.contentful.com/spaces/1nw4q0oohfju/entries/${contentfulEntryId}`;

  const docsPart = asPath.indexOf(docsPathPart);
  const diffHref = features.viewDiffEnabled
    ? [
        asPath.slice(docsPart, docsPathPart.length),
        '/diff',
        asPath.slice(docsPart + docsPathPart.length),
      ].join('')
    : '';

  const originalUrl = asPath.replace('/diff', '');

  const closedToolbar = (
    <Box
      testId="admin-toolbar"
      border={'standard'}
      padding={{ horizontal: '2x', vertical: '1x' }}
      margin={{ horizontal: '2x', vertical: '2x' }}
      className={clsx(styles.switcher)}
    >
      <NativeButton
        trackingId="admin-toolbar-open"
        aria-label="open admin toolbar"
        onClick={() => setIsClosed(false)}
      >
        <Paragraph10 weight="semi-bold" className={styles.label} as="label">
          Tools
        </Paragraph10>
        <Plus className={styles['close-svg']} />
      </NativeButton>
    </Box>
  );

  if (isClosed) {
    return closedToolbar;
  }

  return (
    <Box
      testId="admin-toolbar"
      border={'standard'}
      padding={{ horizontal: '2x', vertical: '1x' }}
      margin={{ horizontal: '2x', vertical: '2x' }}
      className={clsx(styles.switcher)}
    >
      <NativeButton
        trackingId="admin-toolbar-close"
        aria-label="close admin toolbar"
        className={styles['admin-close']}
        onClick={() => setIsClosed(true)}
      >
        <Close className={styles['close-svg']} />
      </NativeButton>
      <Paragraph10
        weight="semi-bold"
        className={styles.label}
        as="label"
        margin={{ top: '1.5x' }}
      >
        Tools
      </Paragraph10>
      <Box className={styles.content}>
        {features.showMarkdownEnabled && (
          <MarketRow
            size="small"
            selected={showingMarkdown}
            onClick={onShowMarkdownClicked}
            trackingId="admin-toolbar-show-markdown"
          >
            <label slot="label">Show markdown</label>
            <MarketToggle slot="control"></MarketToggle>
          </MarketRow>
        )}
        {features.showPreviewEnabled && (
          <MarketRow size="small" trackingId="preview-toolbar-row">
            <label slot="label">Preview</label>
            <MarketButton
              className={styles.control}
              slot="control"
              size="small"
              testId="preview-button"
              trackingId="admin-toolbar-preview-button"
              onClick={onIsPreviewToggleChanged}
              {...(isPreviewLoading && {
                disabled: true,
                isLoading: true,
              })}
            >
              {isPreview ? 'Disable' : 'Enable'}
            </MarketButton>
          </MarketRow>
        )}
        {features.showHtmlHighlightEnabled && (
          <MarketRow
            size="small"
            selected={higlightHtml}
            onClick={onHtmlHighlightClicked}
            trackingId="admin-toolbar-show-html-highlight-row"
          >
            <label slot="label">Show invalid markup</label>
            <MarketToggle slot="control"></MarketToggle>
          </MarketRow>
        )}
        {features.viewDiffEnabled && (
          <MarketRow size="small" trackingId="admin-toolbar-row">
            <label slot="label">Diff</label>
            <Link
              href={diffHref}
              passHref
              omitAnchor={true}
              trackingId={'admin-toolbar-enter-diff'}
            >
              <MarketButton
                className={styles.control}
                slot="control"
                size="small"
                href={diffHref}
                trackingId="admin-toolbar-enter-diff-button"
                testId="admin-toolbar-enter-diff-button"
                onClick={onDiffButtonClicked}
                {...(isDiffButtonClicked && {
                  isLoading: true,
                })}
              >
                Enter
              </MarketButton>
            </Link>
          </MarketRow>
        )}
        {features.exitDiffEnabled && (
          <MarketRow size="small" trackingId="admin-toolbar-exit-diff-row">
            <label slot="label">Diff</label>
            <Link
              href={originalUrl}
              passHref
              omitAnchor={true}
              trackingId={'admin-toolbar-exit-diff'}
            >
              <MarketButton
                className={styles.control}
                slot="control"
                size="small"
                href={originalUrl}
                testId="admin-toolbar-exit-diff-button"
                trackingId="admin-toolbar-exit-diff-button"
                onClick={onDiffButtonClicked}
                {...(isDiffButtonClicked && {
                  isLoading: true,
                })}
              >
                Exit
              </MarketButton>
            </Link>
          </MarketRow>
        )}
        {features.releaseTrainEnabled && <ReleaseTrainFeature />}
        {features.contentfulEnvironmentEnabled && (
          <ContentfulEnvironmentFeature />
        )}
        {features.unpublishedOasEnabled && <UnpublishedOasFeature />}
        {features.viewContentfulEntryEnabled && (
          <MarketRow
            size="small"
            trackingId="admin-toolbar-edit-in-contentful-row"
          >
            <MarketLink
              href={contentfulEditHref}
              target="_blank"
              trackingId="admin-toolbar-edit-in-contentful"
            >
              Edit in contentful
            </MarketLink>
          </MarketRow>
        )}
        <MarketRow
          size="small"
          trackingId="admin-toolbar-edit-in-contentful-row"
        >
          <MarketLink
            href="/dev/guides/docs"
            target="_blank"
            trackingId="admin-toolbar-edit-in-contentful"
          >
            View markdown guides
          </MarketLink>
        </MarketRow>
      </Box>
      <Box className={styles.hitbox}></Box>
    </Box>
  );
};

export { AdminToolbar };
