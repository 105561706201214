import { DocsQueryParams } from '@squareup/dex-types-shared-docs';
import { Box, Heading10 } from '@squareup/dex-ui-shared-base';
import {
  MarketDialog,
  MarketButton,
  MarketHeader,
  MarketInputText,
  MarketRow,
} from '@squareup/dex-ui-shared-market';
import { useRouter } from 'next/router';
import React, {
  FC,
  KeyboardEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';

import styles from '../admin-toolbar.module.css';

const ContentfulEnvironmentFeature: FC = () => {
  const {
    push,
    query: { [DocsQueryParams.contentfulEnv]: contentfulEnvValue },
  } = useRouter();

  const contentfulEnv = contentfulEnvValue as string;

  const inputRef = useRef<globalThis.HTMLMarketInputTextElement>(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [goClicked, setGoClicked] = useState(false);
  const [clearClicked, setClearClicked] = useState(false);

  const onClear = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);

    const contentfulEnvParamValue = searchParams.get(
      DocsQueryParams.contentfulEnv
    );

    if (!contentfulEnvParamValue) {
      setIsDialogOpen(false);
    }
    setClearClicked(true);

    searchParams.delete(DocsQueryParams.contentfulEnv);

    const url = `${window.location.pathname}${
      [...searchParams.keys()].length > 0 ? `?${searchParams.toString()}` : ''
    }`;
    await push(url);

    setClearClicked(false);
    setIsDialogOpen(false);
  }, [push]);

  const onGo = useCallback(async () => {
    if (!inputRef.current) {
      setIsDialogOpen(false);
      return;
    }

    if (inputRef.current.value.length === 0) {
      setGoClicked(true);
      await onClear();
      return;
    }

    if (contentfulEnv === inputRef.current.value) {
      setIsDialogOpen(false);
    }

    setGoClicked(true);

    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set(DocsQueryParams.contentfulEnv, inputRef.current.value);

    const url = `${window.location.pathname}${
      [...newSearchParams.keys()].length > 0
        ? `?${newSearchParams.toString()}`
        : ''
    }`;
    await push(url);

    setGoClicked(false);
    setIsDialogOpen(false);
  }, [onClear, push, contentfulEnv]);

  const onInputKeydown = useCallback<
    KeyboardEventHandler<globalThis.HTMLMarketInputTextElement>
  >(
    async (e) => {
      if (e.key === 'Enter') {
        await onGo();
      }
    },
    [onGo]
  );

  return (
    <>
      <MarketRow size="small" trackingId="contentful-env-row">
        <label slot="label">Environment</label>
        <MarketButton
          className={styles.control}
          slot="control"
          size="small"
          testId="contentful-env-button"
          trackingId="contentful-env-button"
          onClick={() => setIsDialogOpen(true)}
        >
          Open
        </MarketButton>
      </MarketRow>
      {isDialogOpen && (
        <MarketDialog onMarketDialogDismissed={() => setIsDialogOpen(false)}>
          <Box as="main" className={styles.dialog}>
            <MarketHeader>
              <Heading10>Enter a contentful environment</Heading10>
              <MarketButton
                slot="actions"
                size="small"
                rank="primary"
                trackingId="contentful-env-dialog-go"
                testId="contentful-env-dialog-go"
                onClick={onGo}
                {...(goClicked && {
                  disabled: true,
                  isLoading: true,
                })}
              >
                Go
              </MarketButton>
              <MarketButton
                slot="actions"
                size="small"
                rank="primary"
                variant="destructive"
                trackingId="contentful-env-dialog-clear"
                testId="contentful-env-dialog-clear"
                onClick={onClear}
                {...(clearClicked && {
                  disabled: true,
                  isLoading: true,
                })}
              >
                Clear
              </MarketButton>
            </MarketHeader>
            <Box margin={{ bottom: '1x' }} />
            <MarketInputText
              testId="contentful-env-input"
              trackingId="contentful-env-input"
              autofocus={true}
              size="medium"
              value={contentfulEnv || ''}
              ref={inputRef}
              onKeyDown={onInputKeydown}
            >
              <label>Environment</label>
            </MarketInputText>
          </Box>
        </MarketDialog>
      )}
    </>
  );
};

export { ContentfulEnvironmentFeature };
